import React, { useEffect } from 'react';
import Image from 'next/image';

import { useLocale } from '@app/hooks';
import Video from 'components/Video';

type FacebookFeedPropTypes = {
  previews: any;
};

const DynamicLocationAdsPreview: React.FC<FacebookFeedPropTypes> = ({ previews }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.PreviewGroup.index',
  };
  const { t } = useLocale();

  const [feedPreview, setFeedPreview] = React.useState<any>();
  const [storyPreview, setStoryPreview] = React.useState<any>();

  useEffect(() => {
    if (previews?.feed) setFeedPreview(previews.feed);
    if (previews?.story) setStoryPreview(previews.story);
  }, [previews]);

  if (!feedPreview && !storyPreview) {
    return (
      <div className="flex items-center justify-center h-full">
        <span className="px-6 py-3 rounded-3 bg-blue-300 bg-opacity-10 text-blue-300 text-4 select-none">
          {t('labels.empty', SCOPE_OPTIONS)}
        </span>
      </div>
    );
  }

  return (
    <>
      {(feedPreview || storyPreview) &&
      (feedPreview?.attachment_type === 'Attachment::Image' ||
        storyPreview?.attachment_type === 'Attachment::Image' ||
        feedPreview?.attachmentType === 'image' ||
        storyPreview?.attachmentType === 'image') ? (
        <div className="bg-white rounded-3 drop-shadow-box w-140 select-none flex gap-4 p-6">
          <div className="flex flex-col gap-3">
            <span className="text-center">{t('titles.short', SCOPE_OPTIONS)}</span>
            {feedPreview && (
              <Image
                src={
                  previews?.feed?.data?.preview_url?.normal_preview?.toString() ||
                  previews?.feed?.data?.preview_url?.short_preview?.toString() ||
                  previews?.feed?.data?.file?.url ||
                  previews?.feed?.file?.url ||
                  previews?.feed?.preview_url?.short_preview ||
                  previews?.feed?.preview_url?.normal_preview ||
                  ''
                }
                alt="template"
                className="object-cover z-1"
                width="360"
                height="360"
              />
            )}
            {storyPreview && (
              <Image
                src={
                  previews?.story?.data?.preview_url?.normal_preview?.toString() ||
                  previews?.story?.data?.preview_url?.short_preview?.toString() ||
                  previews?.story?.data?.file?.url ||
                  previews?.story?.file?.url ||
                  previews?.story?.preview_url?.short_preview ||
                  previews?.story?.preview_url?.normal_preview ||
                  ''
                }
                alt="template"
                className="object-cover z-1"
                width="360"
                height="640"
              />
            )}
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-center">{t('titles.long', SCOPE_OPTIONS)}</span>
            {feedPreview && (
              <Image
                src={
                  previews?.feed?.data?.preview_url?.normal_preview?.toString() ||
                  previews?.feed?.data?.preview_url?.long_preview?.toString() ||
                  previews?.feed?.data?.file?.url ||
                  previews?.feed?.file?.url ||
                  previews?.feed?.preview_url?.long_preview ||
                  previews?.feed?.preview_url?.normal_preview ||
                  ''
                }
                alt="template"
                className="object-cover z-1"
                width="360"
                height="360"
              />
            )}
            {storyPreview && (
              <Image
                src={
                  previews?.story?.data?.preview_url?.normal_preview?.toString() ||
                  previews?.story?.data?.preview_url?.long_preview?.toString() ||
                  previews?.story?.data?.file?.url ||
                  previews?.story?.file?.url ||
                  previews?.story?.preview_url?.long_preview ||
                  previews?.story?.preview_url?.normal_preview ||
                  ''
                }
                alt="template"
                className="object-cover z-1"
                width="360"
                height="640"
              />
            )}
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-3 drop-shadow-box w-140 select-none flex gap-4 p-6">
          {feedPreview && (
            <div className="flex flex-col gap-3">
              <Video source={previews?.feed?.data} isMuted styleType="listing" />
            </div>
          )}
          {storyPreview && (
            <div className="flex flex-col gap-3">
              <Video source={storyPreview?.url} isMuted styleType="listing" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DynamicLocationAdsPreview;
