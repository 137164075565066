import { CanvaTemplatesGMApi } from "api/api";
import { AxiosInstance } from "axios";
import useAxios from "../useAxios";
import useAuth from "../useAuth";
import { useMutation } from "react-query";

export async function deleteCanvaTemplate(
  companyId: string,
  id: string,
  axiosInstance: AxiosInstance
) {
  const api = new CanvaTemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.deleteCanvaTemplate(companyId, id).then((res) => res.data);
}

export default function useDeleteCanvaTemplate() {
  const { api: axiosInstance } = useAxios({ automaticToast: false });
  const { companyId } = useAuth();

  return useMutation((id: string) => deleteCanvaTemplate(companyId, id, axiosInstance));
}
