/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CanvaTemplate } from '../model';
// @ts-ignore
import { CanvaTemplateErrorResponse } from '../model';
// @ts-ignore
import { CanvaTemplateInput } from '../model';
// @ts-ignore
import { CanvaTemplateListResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * CanvaTemplatesGMApi - axios parameter creator
 * @export
 */
export const CanvaTemplatesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create canva template for company
         * @summary Create canva template for company
         * @param {string} companyId The id of the company
         * @param {CanvaTemplateInput} canvaTemplateInput Create a canva template for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvaTemplate: async (companyId: string, canvaTemplateInput: CanvaTemplateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCanvaTemplate', 'companyId', companyId)
            // verify required parameter 'canvaTemplateInput' is not null or undefined
            assertParamExists('createCanvaTemplate', 'canvaTemplateInput', canvaTemplateInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_templates`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvaTemplateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete canva template by id
         * @summary Delete canva template by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCanvaTemplate: async (companyId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCanvaTemplate', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCanvaTemplate', 'id', id)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_templates/{id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get canva template by id
         * @summary Get canva template by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaTemplate: async (companyId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCanvaTemplate', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCanvaTemplate', 'id', id)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_templates/{id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all canva templates for company
         * @summary Get all canva templates for company
         * @param {string} companyId The id of the company
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaTemplates: async (companyId: string, query?: object, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCanvaTemplates', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_templates`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CanvaTemplatesGMApi - functional programming interface
 * @export
 */
export const CanvaTemplatesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CanvaTemplatesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create canva template for company
         * @summary Create canva template for company
         * @param {string} companyId The id of the company
         * @param {CanvaTemplateInput} canvaTemplateInput Create a canva template for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanvaTemplate(companyId: string, canvaTemplateInput: CanvaTemplateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanvaTemplate(companyId, canvaTemplateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete canva template by id
         * @summary Delete canva template by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCanvaTemplate(companyId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCanvaTemplate(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get canva template by id
         * @summary Get canva template by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvaTemplate(companyId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvaTemplate(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all canva templates for company
         * @summary Get all canva templates for company
         * @param {string} companyId The id of the company
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvaTemplates(companyId: string, query?: object, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvaTemplates(companyId, query, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CanvaTemplatesGMApi - factory interface
 * @export
 */
export const CanvaTemplatesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CanvaTemplatesGMApiFp(configuration)
    return {
        /**
         * Create canva template for company
         * @summary Create canva template for company
         * @param {string} companyId The id of the company
         * @param {CanvaTemplateInput} canvaTemplateInput Create a canva template for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvaTemplate(companyId: string, canvaTemplateInput: CanvaTemplateInput, options?: any): AxiosPromise<CanvaTemplate> {
            return localVarFp.createCanvaTemplate(companyId, canvaTemplateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete canva template by id
         * @summary Delete canva template by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCanvaTemplate(companyId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCanvaTemplate(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get canva template by id
         * @summary Get canva template by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaTemplate(companyId: string, id: string, options?: any): AxiosPromise<CanvaTemplate> {
            return localVarFp.getCanvaTemplate(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all canva templates for company
         * @summary Get all canva templates for company
         * @param {string} companyId The id of the company
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaTemplates(companyId: string, query?: object, page?: number, perPage?: number, options?: any): AxiosPromise<CanvaTemplateListResponse> {
            return localVarFp.getCanvaTemplates(companyId, query, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CanvaTemplatesGMApi - interface
 * @export
 * @interface CanvaTemplatesGMApi
 */
export interface CanvaTemplatesGMApiInterface {
    /**
     * Create canva template for company
     * @summary Create canva template for company
     * @param {string} companyId The id of the company
     * @param {CanvaTemplateInput} canvaTemplateInput Create a canva template for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApiInterface
     */
    createCanvaTemplate(companyId: string, canvaTemplateInput: CanvaTemplateInput, options?: any): AxiosPromise<CanvaTemplate>;

    /**
     * Delete canva template by id
     * @summary Delete canva template by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApiInterface
     */
    deleteCanvaTemplate(companyId: string, id: string, options?: any): AxiosPromise<void>;

    /**
     * Get canva template by id
     * @summary Get canva template by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApiInterface
     */
    getCanvaTemplate(companyId: string, id: string, options?: any): AxiosPromise<CanvaTemplate>;

    /**
     * Get all canva templates for company
     * @summary Get all canva templates for company
     * @param {string} companyId The id of the company
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApiInterface
     */
    getCanvaTemplates(companyId: string, query?: object, page?: number, perPage?: number, options?: any): AxiosPromise<CanvaTemplateListResponse>;

}

/**
 * CanvaTemplatesGMApi - object-oriented interface
 * @export
 * @class CanvaTemplatesGMApi
 * @extends {BaseAPI}
 */
export class CanvaTemplatesGMApi extends BaseAPI implements CanvaTemplatesGMApiInterface {
    /**
     * Create canva template for company
     * @summary Create canva template for company
     * @param {string} companyId The id of the company
     * @param {CanvaTemplateInput} canvaTemplateInput Create a canva template for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApi
     */
    public createCanvaTemplate(companyId: string, canvaTemplateInput: CanvaTemplateInput, options?: any) {
        return CanvaTemplatesGMApiFp(this.configuration).createCanvaTemplate(companyId, canvaTemplateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete canva template by id
     * @summary Delete canva template by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApi
     */
    public deleteCanvaTemplate(companyId: string, id: string, options?: any) {
        return CanvaTemplatesGMApiFp(this.configuration).deleteCanvaTemplate(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get canva template by id
     * @summary Get canva template by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApi
     */
    public getCanvaTemplate(companyId: string, id: string, options?: any) {
        return CanvaTemplatesGMApiFp(this.configuration).getCanvaTemplate(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all canva templates for company
     * @summary Get all canva templates for company
     * @param {string} companyId The id of the company
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaTemplatesGMApi
     */
    public getCanvaTemplates(companyId: string, query?: object, page?: number, perPage?: number, options?: any) {
        return CanvaTemplatesGMApiFp(this.configuration).getCanvaTemplates(companyId, query, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
