import React from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';

type AttachmentOptionPropTypes = {
  options: {
    key: string;
    label: string;
  }[];
  value: string[];
  onClick: (key: string) => void;
};

const AttachmentOption: React.FC<AttachmentOptionPropTypes> = ({ options, value = [], onClick }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.AttachmentOption',
  };
  const { t } = useLocale();
  return (
    <div className="flex flex-row gap-8">
      <span className="font-semibold text-3.5 text-gray-900 flex items-center">{t('labels.selectAttachmentOptions', SCOPE_OPTIONS)}</span>
      <div className="grid grid-cols-2 gap-3.5 flex-1">
        {options.map((option) => (
          <button
            key={option.key}
            type="button"
            onClick={() => onClick(option.key)}
            className={cn('p-3 rounded-3 border-0.5 flex flex-row items-center cursor-pointer transition-colors', {
              'border-gray-800 text-gray-900': Array.isArray(value) && value.includes(option.key) === true,
              'border-gray-300 text-gray-500': Array.isArray(value) && value.includes(option.key) === false,
            })}
          >
            <span className="font-semibold text-3.5 text-gray-900">{option.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AttachmentOption;
