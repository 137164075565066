import React from 'react';

import { useLocale } from '@app/hooks';
import { Icons, Video } from '@app/components';
import { ADS_PREVIEW } from '@app/constants';

type InstagramStoryPropTypes = {
  companyName?: string;
  image: string;
  profilePicture?: string;
  targetingType?: string;
  attachmentType: 'video' | 'image';
};

const InstagramStory: React.FC<InstagramStoryPropTypes> = ({ companyName, image, targetingType, profilePicture, attachmentType }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPreview.InstagramStory',
  };
  const { t } = useLocale();

  return (
    <div className="bg-white select-none relative w-[320px] h-[567px]">
      <div className="absolute z-1 block aspect-w-9 aspect-h-16 w-full">
        {attachmentType === 'video' ? (
          <Video source={image} isMuted styleType="preview" />
        ) : (
          <img src={image} alt="story_image" className="object-cover w-full h-full" />
        )}
      </div>
      <div className="absolute z-2 top-2 w-full bg-gray-400 rounded-full h-0.5 mb-4 opacity-60">
        <div className="bg-gray-50 h-0.5 rounded-full opacity-85 story-loading"></div>
      </div>
      <div className="absolute z-2 top-4 w-full flex flex-row justify-between px-2">
        <div className="flex flex-col w-[12%] rounded-full justify-center bg-gray-200 self-center aspect-square">
          <img src={profilePicture} alt="profile" className="object-cover rounded-full" />
        </div>
        <span className="flex flex-row justify-between w-[88%]">
          <div className="flex flex-col ml-2 self-center">
            <span className="font-medium text-3 text-white">{companyName || ADS_PREVIEW.COMPANY_NAME}</span>
            <span className="text-small leading-none text-white text-2.5 opacity-70">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
          </div>
          <div className="flex flex-row self-center">
            <Icons.TreeDot className="w-4 h-4 fill-white" />
            <Icons.Close className="w-3.5 h-3.5 ml-4 fill-white" />
          </div>
        </span>
      </div>
      <span className="absolute z-2 left-2 bottom-0 right-2 h-18 flex justify-center">
        <Icons.ChevronUp className="w-5 h-5 z-2 text-white" />

        {targetingType && (
          <div className="flex flex-col items-center justify-center absolute bottom-2 bg-white w-auto px-4.5 py-2.5 h-auto rounded-full">
            <span className="text-black font-medium text-3 capitalize">{t(`callToAction.${targetingType}`)}</span>
          </div>
        )}
      </span>
    </div>
  );
};

export default InstagramStory;
