import type { TemplateTargetingPublisherPlatformsEnum, TemplateTargetingSubCampaignTypeEnum, Template as TemplateType } from '@app/api';
import { array } from '@app/lib';
import { IMAGE_PLACEMENT_TYPE, PUBLISHER_PLATFORMS, QUERY_TYPES } from '@app/constants';

function dataToFormData(data: TemplateType) {
  const { attachments } = data;

  return {
    name: data.name,
    images: {
      story: attachments.find((attachment) => attachment?.placement_type === IMAGE_PLACEMENT_TYPE.STORY) ?? null,
      feed: attachments.find((attachment) => attachment?.placement_type === IMAGE_PLACEMENT_TYPE.FEED) ?? null,
    },
    message: data.creative.message,
    headline: data.creative.headline,
    description: data.creative.description,
    platforms: data.targeting.publisher_platforms,
    bannerbear_feed_template_id: data.external_ids?.bannerbear_feed_template_id,
    bannerbear_story_template_id: data.external_ids?.bannerbear_story_template_id,
    allowed_branch_ids: data.allowed_branch_ids,
    branch_type: data?.allowed_branch_ids?.length === 0 ? QUERY_TYPES.all : 'custom',
    attachment_type: data.attachment_type,
  };
}

function multipleDataToFormData(data: TemplateType[]) {
  return {
    templates: data.map((template) => {
      const { attachments } = template;

      return {
        name: template.name,
        images: {
          story: attachments.find((attachment) => attachment?.placement_type === IMAGE_PLACEMENT_TYPE.STORY) ?? null,
          feed: attachments.find((attachment) => attachment?.placement_type === IMAGE_PLACEMENT_TYPE.FEED) ?? null,
        },
        message: template.creative.message,
        headline: template.creative.headline,
        description: template.creative.description,
        platforms: template.targeting.publisher_platforms,
        bannerbear_feed_template_id: template.external_ids?.bannerbear_feed_template_id,
        bannerbear_story_template_id: template.external_ids?.bannerbear_story_template_id,
      };
    }),
  };
}

function formDataToData(data: TemplateFormDataType) {
  //TODO: types and refactor based on functional programmming princeples
  const attachments = [];

  if (data.images?.story) {
    const storyImage = data.images.story;
    attachments.push({
      id: storyImage.id,
      placement_type: storyImage.placement_type,
    });
  }

  if (data.images?.feed) {
    const feedImage = data.images.feed;
    attachments.push({
      id: feedImage.id,
      placement_type: feedImage.placement_type,
    });
  }

  const template = {
    name: data.name,
    attachments: attachments,
    facebook_lead_gen_form_id: data.facebookLeadGenFormId,
    creative: {
      message: data.message,
      headline: data.headline,
      description: data.description,
    },
    targeting: {
      publisher_platforms: data.platforms,
      facebook_positions: data.facebookPositions,
      instagram_positions: data.instagramPositions,
    },
    bannerbear_feed_template_id: '',
    bannerbear_story_template_id: '',
    allowed_branch_ids: data.allowedBranchIds,
  };

  if (data.bannerbear_feed_template_id) {
    template.bannerbear_feed_template_id = data.bannerbear_feed_template_id;
  }

  if (data.bannerbear_story_template_id) {
    template.bannerbear_story_template_id = data.bannerbear_story_template_id;
  }

  if (data.templateBranchType === QUERY_TYPES.all) {
    template.allowed_branch_ids = [];
  }

  return {
    template,
  };
}

function multipleFormDataToData(data: TemplateFormDataType[]) {
  return {
    templates: data.map((templateData, index) => {
      const attachments = [];

      if (templateData.images?.story) {
        const storyImage = templateData.images.story;
        attachments.push({
          id: storyImage.id,
          placement_type: storyImage.placement_type,
        });
      }

      if (templateData.images?.feed) {
        const feedImage = templateData.images.feed;
        attachments.push({
          id: feedImage.id,
          placement_type: feedImage.placement_type,
        });
      }

      const template = {
        parent: index === 0,
        name: templateData.name,
        attachments: attachments,
        facebook_lead_gen_form_id: templateData.facebookLeadGenFormId,
        creative: {
          message: templateData.message,
          headline: templateData.headline,
          description: templateData.description,
        },
        targeting: {
          publisher_platforms: templateData.platforms,
          facebook_positions: templateData.facebookPositions,
          instagram_positions: templateData.instagramPositions,
        },
        bannerbear_feed_template_id: '',
        bannerbear_story_template_id: '',
        allowed_branch_ids: templateData.allowedBranchIds,
      };

      if (templateData.bannerbear_feed_template_id) {
        template.bannerbear_feed_template_id = templateData.bannerbear_feed_template_id;
      }

      if (templateData.bannerbear_story_template_id) {
        template.bannerbear_story_template_id = templateData.bannerbear_story_template_id;
      }

      if (templateData.templateBranchType === QUERY_TYPES.all) {
        template.allowed_branch_ids = [];
      }

      return template;
    }),
  };
}

function previewImage(data: TemplateType) {
  if (array.isEmpty(data?.attachments)) {
    return '';
  }

  const attachments = data.attachments;
  const feedImage = attachments?.find((attachment) => attachment?.placement_type === IMAGE_PLACEMENT_TYPE.FEED);

  if (feedImage?.file) {
    return feedImage.file.url;
  }

  const firstImage = array.first(attachments);

  return firstImage?.file.url;
}

function handleObjectKeyName(keyName: string) {
  return keyName.slice(0, -2);
}

function groupFormDataObjects(obj: any, predicate: any) {
  //FIXME: refactor
  //FIXME: predicate not needed
  return (
    obj &&
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res: any, key: any) => {
        const newKey = handleObjectKeyName(key);
        res[newKey] = res[newKey] ?? [];
        res[newKey].push(obj[key]);
        return res;
      }, {})
  );
}

function handleAttachments(types: Array<string>, images: Record<string, any>) {
  //FIXME: refactor
  const contentAttachment: Array<{ id: string; url: string; placement_type: string }> = [];
  const attachments = groupFormDataObjects(images, (data: any) => data !== undefined);

  attachments &&
    Object.keys(attachments).map((item) =>
      attachments[item].map(
        (attachment: { id: string; url: string; placement_type: string }) =>
          types.includes(attachment?.placement_type as any) && contentAttachment.push(attachment)
      )
    );

  return contentAttachment;
}

function handleCreative(creatives: Record<string, any>) {
  //FIXME: refactor
  const creative = groupFormDataObjects(creatives, (data: any) => data !== '');
  return creative;
}

function hasGooglePlatform(platforms: Array<TemplateTargetingPublisherPlatformsEnum> | undefined) {
  return platforms && platforms?.includes(PUBLISHER_PLATFORMS.GOOGLE as TemplateTargetingPublisherPlatformsEnum);
}

function hasDynamicLocationAdsPlatform(platforms: Array<TemplateTargetingPublisherPlatformsEnum> | undefined) {
  return platforms && platforms.includes(PUBLISHER_PLATFORMS.DYNAMICLOCATIONADS as TemplateTargetingPublisherPlatformsEnum);
}

function hasSmartInCampaignType(campaignTypes: TemplateTargetingSubCampaignTypeEnum | undefined) {
  return campaignTypes?.includes(PUBLISHER_PLATFORMS.SMART);
}

export default {
  dataToFormData,
  multipleDataToFormData,
  formDataToData,
  previewImage,
  groupFormDataObjects,
  handleAttachments,
  handleCreative,
  handleObjectKeyName,
  hasGooglePlatform,
  hasSmartInCampaignType,
  hasDynamicLocationAdsPlatform,
  multipleFormDataToData,
};
