import React from 'react';

import { CardButton } from '@app/components';
import { useRouter } from 'next/router';

import { useLocale } from '@app/hooks';

type CategoryPropTypes = {
  canvaCategories: {
    canva_categories: Array<{
      id: string;
      created_at: string;
      name: string;
      tags: Array<any>;
      updated_at: string;
      url: string;
    }>;
  };
  scopeOptions: {
    scope: string;
  };
};

const Category: React.FC<CategoryPropTypes> = ({ canvaCategories, scopeOptions }) => {
  const { t } = useLocale();
  const router = useRouter();

  const IMAGES: Record<string, string> = {
    background: '/images/canva-bg.png',
    sticker: '/images/canva-stickers.png',
    product: '/images/canva-products.png',
    template: '/images/canva-templates.png',
  };

  const categories = [{ id: 'canva-templates', name: 'template', url: '/canva-templates' }, ...(canvaCategories?.canva_categories || [])];

  return (
    <div className="flex justify-center my-17 lg:my-34">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
        {categories.map((item) => (
          <CardButton
            key={item.id}
            id={item.id}
            imageUrl={IMAGES[item.name] || '/images/default.png'}
            label={t(`labels.${item.name}`, scopeOptions)}
            onClick={() => router.push({ pathname: item.url || `/canva-categories/${item.id}` })}
            isDeletable={false}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
