import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import cn from 'classnames';

import { useFormContext } from 'react-hook-form';

import { Form, Box, Icons } from '@app/components';
import { useLocale, useActiveInput, api, useAuth } from '@app/hooks';

import BannerbearInput from '../DynamicLocationAdsForm/BannerbearInput';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

import { Branch } from 'api/model';

import AttachmentOption from './AttachmentOption';

type FormPropTypes = {
  branches?: Array<Branch>;
  bannerbearSession: BannerbearSessionStateType;
  setBannerbearSession: Dispatch<SetStateAction<BannerbearSessionStateType>>;
};

const MAX_MESSAGE_LENGTH = 255;
const DEFAULT_INDEX = 0;

const MetaForm: React.FC<FormPropTypes> = ({ branches, bannerbearSession, setBannerbearSession }) => {
  const { companyId } = useAuth();
  const { t } = useLocale();
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.index',
  };
  const [feedUploadProgressState, setFeedUploadProgressState] = useState<number>(0);
  const [storyUploadProgressState, setStoryUploadProgressState] = useState<number>(0);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    control,
  } = useFormContext();
  const messageWatch = watch('message');
  const templateBranchTypeWatch = watch('templateBranchType');
  const attachmentTypeWatch = watch(`templates[${DEFAULT_INDEX}].attachmentType`);
  const feedVideoFileWatch = watch(`templates[${DEFAULT_INDEX}].videos.feed.id`);
  const storyVideoFileWatch = watch(`templates[${DEFAULT_INDEX}].videos.story.id`);
  const activeInput = useActiveInput([
    `templates[${DEFAULT_INDEX}].message`,
    `templates[${DEFAULT_INDEX}].headline`,
    `templates[${DEFAULT_INDEX}].description`,
  ]);

  const BRANCH_OPTIONS = [
    { label: t('form.branches.all', SCOPE_OPTIONS), value: 'all', defaultChecked: true },
    { label: t('form.branches.custom', SCOPE_OPTIONS), value: 'custom' },
  ];

  const ATTACHMENT_OPTIONS = [
    {
      key: 'image',
      label: t('form.attachmentOptions.image', SCOPE_OPTIONS),
    },
    {
      key: 'video',
      label: t('form.attachmentOptions.video', SCOPE_OPTIONS),
    },
  ];

  const branchSelectOptions = useMemo(
    () =>
      branches?.map((branch) => ({
        label: branch.name,
        value: branch.id,
      })) || [],
    [branches]
  );

  function InputVariables({ id, activeInput }: { id: string; activeInput: string | null }) {
    if (activeInput !== id) {
      return null;
    }

    function handleText(value: string) {
      const currentText = getValues(id);

      if (!currentText) {
        setValue(id, value);
        return;
      }

      setValue(id, `${currentText} ${value}`);
    }

    return <Form.BranchButtons className="mb-4.5 -mt-1.5" onClick={handleText} />;
  }

  const handleAttachmentTypeClick = (key: string) => {
    setValue(`templates[${DEFAULT_INDEX}].attachmentType`, key);
  };

  const { mutate: uploadFeedVideo, isLoading: isUploadFeedVideoLoading } = api.useUploadFeedVideo({
    onSuccess: (data: any) => {
      setValue(`templates.0.videos.feed.id`, data?.result?.id);
      setValue(`templates.0.videos.feed.url`, data?.result?.file?.url);
    },
  });

  const { mutate: uploadStoryVideo, isLoading: isUploadStoryVideoLoading } = api.useUploadStoryVideo({
    onSuccess: (data: any) => {
      setValue(`templates.0.videos.story.id`, data?.result?.id);
      setValue(`templates.0.videos.story.url`, data?.result?.file?.url);
    },
  });

  function fileStatusText(type: string) {
    if (type === IMAGE_PLACEMENT_TYPE.FEED) {
      if (isUploadFeedVideoLoading) {
        return t('form.feed.uploading', SCOPE_OPTIONS);
      }

      if (feedVideoFileWatch) {
        return t('form.feed.uploaded', SCOPE_OPTIONS);
      }

      return t('form.feed.upload', SCOPE_OPTIONS);
    }
    if (type === IMAGE_PLACEMENT_TYPE.STORY) {
      if (isUploadStoryVideoLoading) {
        return t('form.story.uploading', SCOPE_OPTIONS);
      }

      if (storyVideoFileWatch) {
        return t('form.story.uploaded', SCOPE_OPTIONS);
      }

      return t('form.story.upload', SCOPE_OPTIONS);
    }
  }

  function uploadFeedFile(file: File | undefined) {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);

    uploadFeedVideo({
      companyId: companyId,
      uploadVideoInput: formData,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.round((loaded / total) * 100);
        setFeedUploadProgressState(progress);
      },
    });
  }

  function uploadStoryFile(file: File | undefined) {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);

    uploadStoryVideo({
      companyId: companyId,
      uploadVideoInput: formData,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.round((loaded / total) * 100);
        setStoryUploadProgressState(progress);
      },
    });
  }

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id={`templates[${DEFAULT_INDEX}].name`}
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          requiredSign={true}
          error={errors.name}
          {...register(`templates[${DEFAULT_INDEX}].name`, {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
            maxLength: { value: MAX_MESSAGE_LENGTH, message: t('form.message.errors.maxLength', SCOPE_OPTIONS) },
          })}
          className="mb-4"
        />
      </Box>
      <Box>
        <AttachmentOption options={ATTACHMENT_OPTIONS} value={[attachmentTypeWatch]} onClick={handleAttachmentTypeClick} />
      </Box>
      {attachmentTypeWatch === 'video' ? (
        <>
          <Box>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <span className="font-medium">
                  {t('form.video.feedAttachment', SCOPE_OPTIONS)} <span className="text-red-500">*</span>
                </span>
                {errors?.videos?.feed?.id?.message && feedVideoFileWatch === undefined && (
                  <div className="flex items-center">
                    <span className="inline text-red-500 text-3 whitespace-nowrap">{errors?.videos?.feed?.id?.message}</span>
                  </div>
                )}
              </div>
              <label
                htmlFor="upload-feed"
                className={cn(
                  'cursor-pointer w-full bg-indigo-100 flex items-center justify-center py-6 rounded-xl border border-gray-400',
                  {
                    'opacity-40 cursor-not-allowed': isUploadFeedVideoLoading,
                  }
                )}
              >
                <Icons.File
                  className={cn('w-6 h-6 mr-2', { 'text-blue-400': !feedVideoFileWatch, 'text-green-600': feedVideoFileWatch })}
                />
                <span className={cn({ 'text-blue-400': !feedVideoFileWatch, 'text-green-600': feedVideoFileWatch })}>
                  {fileStatusText(IMAGE_PLACEMENT_TYPE.FEED)}
                </span>
              </label>
              <input id="upload-feed" type="file" className="hidden" onChange={(e) => uploadFeedFile(e?.target?.files?.[0])}></input>
              {isUploadFeedVideoLoading && feedUploadProgressState > 0 && (
                <div className="w-full bg-gray-200 rounded-full h-8 relative">
                  <div
                    className="bg-green-600 h-8 rounded-full transition-all duration-300"
                    style={{ width: `${feedUploadProgressState}%` }}
                  >
                    <h1 className="text-blue-400 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      %{feedUploadProgressState}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </Box>
          <Box>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <span className="font-medium">
                  {t('form.video.storyAttachment', SCOPE_OPTIONS)} <span className="text-red-500">*</span>
                </span>
                {errors?.videos?.story?.id?.message && storyVideoFileWatch === undefined && (
                  <div className="flex items-center">
                    <span className="inline text-red-500 text-3 whitespace-nowrap">{errors?.videos?.story?.id?.message}</span>
                  </div>
                )}
              </div>
              <label
                htmlFor="upload-story"
                className={cn(
                  'cursor-pointer w-full bg-indigo-100 flex items-center justify-center py-6 rounded-xl border border-gray-400',
                  {
                    'opacity-40 cursor-not-allowed': isUploadStoryVideoLoading,
                  }
                )}
              >
                <Icons.File
                  className={cn('w-6 h-6 mr-2', { 'text-blue-400': !storyVideoFileWatch, 'text-green-600': storyVideoFileWatch })}
                />
                <span className={cn({ 'text-blue-400': !storyVideoFileWatch, 'text-green-600': storyVideoFileWatch })}>
                  {fileStatusText(IMAGE_PLACEMENT_TYPE.STORY)}
                </span>
              </label>
              <input id="upload-story" type="file" className="hidden" onChange={(e) => uploadStoryFile(e?.target?.files?.[0])}></input>
              {isUploadStoryVideoLoading && storyUploadProgressState > 0 && (
                <div className="w-full bg-gray-200 rounded-full h-8 relative">
                  <div
                    className="bg-green-600 h-8 rounded-full transition-all duration-300"
                    style={{ width: `${storyUploadProgressState}%` }}
                  >
                    <h1 className="text-blue-400 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      %{storyUploadProgressState}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <BannerbearInput
              index={DEFAULT_INDEX}
              type={IMAGE_PLACEMENT_TYPE.FEED}
              setBannerbearSession={setBannerbearSession}
              bannerbearSession={bannerbearSession}
              isMetaTemplate={true}
            />
          </Box>
          <Box>
            <BannerbearInput
              index={DEFAULT_INDEX}
              type={IMAGE_PLACEMENT_TYPE.STORY}
              setBannerbearSession={setBannerbearSession}
              bannerbearSession={bannerbearSession}
              isMetaTemplate={true}
            />
          </Box>
        </>
      )}
      <Box>
        <Form.Textarea
          id={`templates[${DEFAULT_INDEX}].message`}
          label={t('form.message.label', SCOPE_OPTIONS)}
          placeholder={t('form.message.placeholder', SCOPE_OPTIONS)}
          requiredSign={true}
          error={errors.message}
          maxLength={MAX_MESSAGE_LENGTH}
          length={messageWatch?.length ?? 0}
          {...register(`templates[${DEFAULT_INDEX}].message`, {
            required: { value: true, message: t('form.message.errors.required', SCOPE_OPTIONS) },
            maxLength: { value: MAX_MESSAGE_LENGTH, message: t('form.message.errors.maxLength', SCOPE_OPTIONS) },
          })}
        />
        <InputVariables id={`templates[${DEFAULT_INDEX}].message`} activeInput={activeInput} />
        <Form.Input
          type="text"
          id={`templates[${DEFAULT_INDEX}].headline`}
          label={t('form.headline.label', SCOPE_OPTIONS)}
          placeholder={t('form.headline.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${DEFAULT_INDEX}].headline`, {
            required: { value: true, message: t('form.headline.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.headline}
        />
        <InputVariables id={`templates[${DEFAULT_INDEX}].headline`} activeInput={activeInput} />
        <Form.Input
          type="text"
          id={`templates[${DEFAULT_INDEX}].description`}
          label={t('form.description.label', SCOPE_OPTIONS)}
          placeholder={t('form.description.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${DEFAULT_INDEX}].description`, {
            required: { value: true, message: t('form.description.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.description}
        />
        <InputVariables id={`templates[${DEFAULT_INDEX}].description`} activeInput={activeInput} />
      </Box>
      <Box>
        <Form.RadioGroup name={`templateBranchType`} register={register} options={BRANCH_OPTIONS} />
        {templateBranchTypeWatch == 'custom' && (
          <Form.Select
            isMulti
            label={t('form.branches.label', SCOPE_OPTIONS)}
            name="allowedBranchIds"
            rules={{
              required: { value: true, message: t('form.branches.errors.required', SCOPE_OPTIONS) },
            }}
            className="mt-4"
            control={control}
            options={branchSelectOptions}
          />
        )}
      </Box>
    </form>
  );
};

export default MetaForm;
