import React from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { BRANCH_KEYS, LOCATION_KEYS } from '@app/constants';

type BranchButtonsPropTypes = {
  onClick: (key: string) => void;
  className?: string;
  buttonClassName?: string;
  isDynamicLocationAds?: boolean;
};

const BranchButtons: React.FC<BranchButtonsPropTypes> = ({ onClick, className, buttonClassName, isDynamicLocationAds }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Form.BranchButtons',
  };
  const { t } = useLocale();
  const BRANCH_BUTTONS = [
    { id: t('actions.name', SCOPE_OPTIONS), key: BRANCH_KEYS.NAME },
    { id: t('actions.address', SCOPE_OPTIONS), key: BRANCH_KEYS.ADDRESS },
    { id: t('actions.phone', SCOPE_OPTIONS), key: BRANCH_KEYS.PHONE },
    { id: t('actions.website', SCOPE_OPTIONS), key: BRANCH_KEYS.WEBSITE },
    { id: t('actions.addressDirection', SCOPE_OPTIONS), key: BRANCH_KEYS.ADDRESS_DIRECTION },
    { id: t('actions.city', SCOPE_OPTIONS), key: BRANCH_KEYS.CITY },
    { id: t('actions.district', SCOPE_OPTIONS), key: BRANCH_KEYS.DISTRICT },
  ];
  const LOCATION_BUTTONS = [
    { id: t('actions.name', SCOPE_OPTIONS), key: LOCATION_KEYS.NAME },
    { id: t('actions.address', SCOPE_OPTIONS), key: LOCATION_KEYS.ADDRESS },
    { id: t('actions.phone', SCOPE_OPTIONS), key: LOCATION_KEYS.PHONE },
    { id: t('actions.website', SCOPE_OPTIONS), key: LOCATION_KEYS.WEBSITE },
    { id: t('actions.locationCity', SCOPE_OPTIONS), key: LOCATION_KEYS.LOCATION_CITY },
    { id: t('actions.locationDistrict', SCOPE_OPTIONS), key: LOCATION_KEYS.LOCATION_DISTRICT },
    { id: t('actions.finalUrl', SCOPE_OPTIONS), key: LOCATION_KEYS.FINAL_URL },
  ];
  const BUTTONS = isDynamicLocationAds ? LOCATION_BUTTONS : BRANCH_BUTTONS;

  return (
    <div className={cn('flex flex-row gap-2 flex-wrap items-center justify-center', className)}>
      {BUTTONS.map((button) => (
        <button
          type="button"
          key={button.id}
          onClick={() => onClick(button.key)}
          className={cn(
            'bg-white text-gray-900 border border-gray-400 hover:border-gray-900 py-1 px-2 text-3 font-semibold rounded-2 whitespace-nowrap',
            buttonClassName
          )}
        >
          {button.id}
        </button>
      ))}
    </div>
  );
};

export default BranchButtons;
