import React from 'react';

type VideoPropTypes = {
  source: string;
  isMuted: boolean;
  styleType: 'preview' | 'listing';
};

const Video: React.FC<VideoPropTypes> = ({ source, isMuted, styleType }) => {
  const style = {
    preview: 'object-cover w-full h-full',
    listing: 'my-3 rounded aspect-1 object-contain bg-black',
  };

  return (
    <video
      src={source}
      muted={isMuted}
      onMouseEnter={(e) => e.currentTarget.play()}
      onMouseLeave={(e) => e.currentTarget.pause()}
      className={style[styleType]}
    >
      <track kind="captions" />
    </video>
  );
};

export default Video;
