const BRANCH_KEYS = Object.freeze({
  NAME: 'BRANCH_NAME',
  ADDRESS: 'BRANCH_ADDRESS',
  PHONE: 'BRANCH_PHONE',
  WEBSITE: 'BRANCH_WEBSITE',
  ADDRESS_DIRECTION: 'ADDRESS_DIRECTION',
  CITY: 'BRANCH_CITY',
  DISTRICT: 'BRANCH_DISTRICT',
});

export default BRANCH_KEYS;
