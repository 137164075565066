import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { useAuth, useAxios } from '@app/hooks';

import { CanvaTemplatesGMApi } from '@app/api';

export function listCanvaTemplates(
  companyId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaTemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getCanvaTemplates(companyId, query, page, perPage).then((res) => res.data);
}

export default function useListCanvaTemplates({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListCanvaTemplates', companyId, params.page, params.perPage, params.query],
    () => listCanvaTemplates(companyId, params.page, params.perPage, params.query, axiosInstance),
    props
  );
}
