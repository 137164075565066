import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAxios } from '@app/hooks';

export function uploadFeedVideo(
  companyId: string,
  uploadFeedVideoInput: object,
  placementType: string,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
  axiosInstance: AxiosInstance
) {
  return axiosInstance
    .post(`v2/general_manager_account/companies/${companyId}/attachments/videos/upload`, uploadFeedVideoInput, {
      onUploadProgress: onUploadProgress,
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => {
      return {
        result: res?.data,
        placementType: placementType,
      };
    });
}

export default function useUploadFeedVideo(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; uploadVideoInput: object; placementType: string; onUploadProgress: any }) =>
      uploadFeedVideo(values.companyId, values.uploadVideoInput, values.placementType, values.onUploadProgress, axiosInstance),
    props
  );
}
